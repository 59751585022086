<template>
    <section :id="cmsBlock.anchorTag" class="component-container" :class="cmsBlock.counterStyle">
        <div class="counters" ref="counters" :style="gridCells">
            <div class="counter" v-for="counter in counters" :key="counter.id" :class="counter.style">
                <img class="polygon-image" v-if="counter.style == 'polygon'" :src="polygonImage" />
                <div class="number">{{ counter.currentNumber }}<span class="unit"> {{ counter.unit }}</span></div>
                <div class="description">{{ counter.description }}</div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'CounterBlock',

    props: [
        'cmsBlock'
    ],

    data() {
        return {
            counters: [],
            countersFinished: false,
            polygonImage: require('@/assets/polygon.svg')
        }
    },

    mounted() {
        var self = this
        var observer = new IntersectionObserver(
            function (entries) {
                if (entries[0].isIntersecting === true) {
                    self.triggerCounters()
                }
            }, {
            threshold: [0]
        }
        )
        observer.observe(this.$refs.counters)

        this.cmsBlock.counters.forEach(function (counter) {
            self.counters.push({
                id: counter.id,
                style: counter.style,
                number: counter.number,
                unit: counter.unit,
                description: self.localizeAlt(counter, 'description'),
                currentNumber: 0
            })
        })
    },

    computed: {
        gridCells() {
            var gridColStyle = ''
            this.counters.forEach(function (counter) {
                gridColStyle += '1fr '
            })
            return {
                gridTemplateColumns: gridColStyle
            }
        }
    },

    methods: {
        triggerCounters() {
            if (!this.countersFinished) {
                var self = this
                this.counters.forEach(function (counter, i) {
                    var duration = 1000 + (i + 1) * 400
                    self.count(counter, duration)
                    self.countersFinished = true
                })
            }
        },

        count(counter, duration) {
            var startTimestamp = null
            const step = function (timestamp) {
                if (!startTimestamp) {
                    startTimestamp = timestamp
                }
                const progress = Math.min((timestamp - startTimestamp) / duration, 1)
                counter.currentNumber = Math.floor(progress * counter.number)
                if (progress < 1) {
                    window.requestAnimationFrame(step)
                }
            }
            window.requestAnimationFrame(step)
        }
    }
}
</script>

<style lang="scss" scoped>
.component-container {
    .counters {
        display: grid;
        column-gap: 30px;
        max-width: $container-width;
        margin: 0 auto;

        @include breakpoint('tablet') {
            padding-left: 30px;
            padding-right: 30px;
        }

        @include breakpoint('mobile') {
            grid-template-columns: 1fr !important;
            row-gap: 100px;
        }

        .counter {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            position: relative;

            .number {
                font-size: getFontSize('counterLarge', 'desktop');
                font-family: getFontFamily('counterLarge');
                line-height: 0.8em;

                @include breakpoint('tablet') {
                    font-size: getFontSize('counterLarge', 'tablet');
                }

                @include breakpoint('mobile') {
                    font-size: getFontSize('counterLarge', 'mobile');
                }

                .unit {
                    font-size: getFontSize('counterUnitLarge', 'desktop');
                    font-family: getFontFamily('counterUnitLarge');
                    font-weight: bold;

                    @include breakpoint('tablet') {
                        font-size: getFontSize('counterUnitLarge', 'tablet');
                    }

                    @include breakpoint('mobile') {
                        font-size: getFontSize('counterUnitLarge', 'mobile');
                    }
                }
            }

            .description {
                font-size: getFontSize('defaultText', 'desktop');
                font-family: getFontFamily('defaultText');
                font-weight: getFontWeight('defaultText');
                font-style: getFontStyle('defaultText');
                max-width: 300px;
                text-align: center;
                word-break: break-word;
                hyphens: auto;

                @include breakpoint('tablet') {
                    font-size: getFontSize('defaultText', 'tablet');
                }

                @include breakpoint('mobile') {
                    font-size: getFontSize('defaultText', 'mobile');
                }
            }

            &.filled {}

            &.outlined {

                .number {
                    -webkit-text-stroke-width: 3px;
                    -webkit-text-stroke-color: $timberGreen;
                    color: transparent;
                }
            }

            &.polygon {
                justify-content: center;

                .number {
                    font-size: 64px;
                    font-size: getFontSize('counterMedium', 'desktop');
                    margin-bottom: 20px;

                    @include breakpoint('tablet') {
                        font-size: getFontSize('counterMedium', 'tablet');
                        margin-bottom: 10px;
                    }

                    @include breakpoint('mobile') {
                        font-size: getFontSize('counterMedium', 'mobile');
                        margin-bottom: 10px;
                    }

                    .unit {
                        font-size: getFontSize('counterUnitSmall', 'desktop');

                        @include breakpoint('tablet') {
                            font-size: getFontSize('counterUnitSmall', 'tablet');
                        }

                        @include breakpoint('mobile') {
                            font-size: getFontSize('counterUnitSmall', 'mobile');
                        }
                    }
                }

                .description {
                    max-width: 200px;

                    @include breakpoint('tablet') {
                        max-width: 170px;
                    }

                    @include breakpoint('mobile') {
                        max-width: 130px;
                    }
                }

                .polygon-image {
                    position: absolute;
                    width: 350px;

                    @include breakpoint('tablet') {
                        width: 220px;
                    }

                    @include breakpoint('mobile') {
                        width: 200px;
                    }
                }
            }
        }


    }

    &.dark {
        .counters {
            .counter {
                &.outlined {
                    .number {
                        -webkit-text-stroke-color: $lightGrey;
                    }

                }
            }
        }

    }
}
</style>
